.e-container {
    background-color: #F9F5F6;
    display: flex; 
    justify-content: center; 
    /* align-items: center;  */
    flex-direction: column;
}
.pinkText {
    color: #FDCEDF;
}
.e-head {
    font-size: 2rem; 
    font-weight: 600; 
    margin-left: 10rem;
}
.e-wrapper {
    width: 100%; 
    height: 100%;
    top: 25%;
    background-color: #F8E8EE;
    display: flex; 
    justify-content: center;
    align-items: center; 
    flex-direction: column;
    position: relative;
}

.card {
    background-color: white;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: 80%;
    flex-direction: column; 
    font-size: 1.5rem;
    padding: 0.5rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    z-index: 2;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.5); 
}
.grade {
    display: flex; 
    row-gap: 2rem; 
    justify-content: space-between;
    align-items: center; 
    flex-wrap: wrap;
}
.grade>* {
    margin-right: 1rem;
    margin-left: 1rem;
}

.e-wrapper>:nth-child(3) {
    margin-bottom: 6rem;
}
.pink1{
    height: 4rem;
    width: 4rem; 
    background: #FDCEDF;
    border-radius: 999px;
    position: absolute;
    z-index: 1;
    top: 4rem; 
    left: 6rem;
}

.pink2{
    height: 4rem;
    width: 4rem; 
    background: #FDCEDF;
    border-radius: 999px;
    position: absolute;
    z-index: 1;
    top: 14rem; 
    right: 5rem;
}

.pink3{
    height: 4rem;
    width: 4rem; 
    background: #FDCEDF;
    border-radius: 999px;
    position: absolute;
    z-index: 1;
    bottom: 4.5rem; 
    left: 50%;
}
.pink4{
    height: 4rem;
    width: 4rem; 
    background: #FDCEDF;
    border-radius: 999px;
    position: absolute;
    z-index: 1; 
    bottom: 10%; 
    left: 1rem;
}

.pink5{
    height: 4rem;
    width: 4rem; 
    background: #FDCEDF;
    border-radius: 999px;
    position: absolute;
    z-index: 1;
    top: 10%; 
    right: 2%
}

.pink6{
    height: 4rem;
    width: 4rem; 
    background: #FDCEDF;
    border-radius: 999px;
    position: absolute;
    z-index: 1;
    top: 30%; 
    right: 50%;
}