.h-container {
    background-color:  #F8E8EE;
    width: 100%; 
    height: 100%;
    position: relative;
}

.h-wrapper {
    background-color: #F9F5F6;
    justify-content: space-between; 
    align-items: flex-end;
    display: flex;
    row-gap: 2rem ;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%; 
    padding: 1.5rem; 
}

.h-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: fl;
    gap: 3rem;
}
.title {
    position: relative; 
    z-index: 1;
}
.title>h1 {
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 4rem;
    text-shadow: 5px 0 #f96a9e 
    /* #232931; */
}
.pink-circle {
    height: 4rem;
    width: 4rem; 
    background: #FDCEDF;
    border-radius: 999px;
    position: absolute;
    z-index: -1;
    right: 0;
    top: -10%;
}

.desc {
    display: flex; 
    flex-direction: column; 
    justify-content: center;
    align-items: flex-start; 
}
.abtme {
    font-size: 1rem;
    font-weight: 600;
    line-height: 3rem;
}
.button {
    font-weight: 500;
    padding: 0.6rem 1.4rem;
    color: white;
    background: linear-gradient(97.05deg, #4066ff 3.76%, #2949c6 100%);
    border: none;
    border-radius: 4px;
    transition: all 300ms ease-in;
}
  
.button:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.h-right {
  display: flex;
  row-gap: 2rem ;
  justify-content: center;
  margin-left: 3rem;
  align-items: center;
  flex-wrap: wrap;
 }

.img-container {
    width: 25rem; 
    height: 35rem; 
    overflow: hidden; 
    border-radius: 1rem 1rem 1rem 1rem;
    border: 8px solid rgba(255,255,255,0.12);
}
.img-container>img {
    width: 100%; 
    height: 100%;
}