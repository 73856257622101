.a-container {
    position: relative;
    padding: 1.5rem; 
    width: 100%; 
    background-color: #F9F5F6;
    display: flex; 
    flex-direction: column; 
    justify-content: center;
    align-items: flex-start;
}

.heading {
    color:  black;
    font-weight: bold; 
    font-size: 2rem; 
}

.pinkText {
    color: #FDCEDF;
}

.parent {
    position: relative;
}

.a-card {
    background-color: #F8E8EE;
    display: flex;
    row-gap: 2rem; 
    justify-content: center; 
    align-items: center; 
    flex-wrap: wrap;
    z-index: 1;
}
.a-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
} 
.desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
} 
.text {
    color: rgb(140 139 139);
    font-size: 1rem; 
    font-weight: 600;
    line-height: 3rem;
}
.diff {
    color: black;
}
.media {
    display: flex;
    justify-content: center; 
    align-items: center; 
    flex-wrap: wrap;
    z-index: 3;
}
.media>* {
    margin-left: 2rem;
}
.media>*:hover {
    cursor: pointer; 
    transform: scale(1.2);
}
.a-right {
    display: flex; 
    row-gap: 2rem; 
    justify-content: center; 
    align-items: center; 
    flex-wrap: wrap; 
}
.image-container {
    width: 20rem; 
    height: 20rem; 
    overflow: hidden; 
    border-radius: 50% 50%; 
    border: 8px solid rgba(255,255,255,0.12);
    margin: 1rem;
}

.image-container>img {
    width: 100%; 
    height: 100%; 
    object-fit:cover;
}
.extra {
    display: flex; 
    row-gap: 2rem; 
    justify-content: center; 
    align-items: center;
    flex-wrap: wrap;
    width: 100%; 
    position: absolute; 
    top: 13.5rem;
    z-index: 2;
}
.image-left {
    width: 20rem; 
    height: 20rem; 
    overflow: hidden; 
    border-radius: 50% 50%; 
    border: 8px solid rgba(255,255,255,0.12);
    margin-right: 3rem;
    margin-top: 4rem;
}
.image-right {
    width: 22rem; 
    height: 22rem; 
    overflow: hidden; 
    border-radius: 50% 50%; 
    border: 8px solid rgba(255,255,255,0.12);
}
.image-left>img {
    width: 100%; 
    height: 100%; 
    object-fit: cover;
}
.image-right>img {
    width: 100%; 
    height: 100%; 
    object-fit: cover;
}

