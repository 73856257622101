.s-wrapper {
    display: grid;
    padding-top: 80px;
    margin: 45px;
    grid-template-columns: 1fr 1fr; 
    gap:inherit;
    /* height:max-content; */
}

.s-left {
    display: grid; 
    grid-template-rows: repeat(5 , 1fr); 
    gap: 20px;
}
.card1 {
    width: 400px; 
    height: fit-content; 
    background-color: #fff; 
    border:1px black;
    border-radius: 5px; 
    border-left: 14px solid #ffb8d2;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.s-right {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: repeat(3,1fr); 
}

.buttlink {
    font-weight: 500;
    padding: 0.6rem 1.4rem;
    color: white;
    background: linear-gradient(97.05deg, #fec5da 3.76%, #f99cbe 100%);
    border: 2px solid black;
    border-radius: 4px;
    transition: all 300ms ease-in;
    display: flex;
    align-items: center;
}

.buttlink:hover {
    cursor: pointer;
    transform: scale(1.1);
}
.icon-container {
    margin-right: 8px;
}

.icon {
    font-size: 24px;
}