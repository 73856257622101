.p-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* min-height: 100vh; */
    background-color: rgb(222, 241, 248);
    padding-bottom: 150px;
}

.p-wrapper {
    display: grid; 
    grid-template-columns: repeat(3 , 1fr);
    grid-gap : 30px;
    padding: 20px;
}

.card2 {
    max-height: 250px; 
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    text-align: center;
    border-radius: 25px;
    overflow: hidden;
    border-top: 40px solid #ffb8d2;
    border-bottom: 40px solid #ffb8d2;
}


.but {
    font-weight: 500;
    padding: 0.6rem 1.4rem;
    color: white;
    background: linear-gradient(97.05deg, #FDCEDF 3.76%, #ffb8d2 100%);
    border: none;
    border-radius: 4px;
    transition: all 300ms ease-in;
    margin-bottom: 4px;
    margin-top: 4px;
}

.but:hover {
    cursor: pointer;
    transform: scale(1.1);
    margin-bottom: 4px;
    margin-top: 4px;
}


.pop-up-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content; 
    width: 40rem; 
    padding: 2rem;
    background-color: #f8e7ed;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
}

.pu-wrapper {
    display: flex; 
    flex-direction: column;
    height: 100%;
}

.abt {
    overflow-y: auto; 
    padding: 10px; 
}

.links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    margin-bottom: 0;
    margin-top: 18px;
    margin-right: 10px;
    margin-left: 10px;
    background-color: #ffb8d2;
}
